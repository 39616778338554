input[type=checkbox], input[type=radio] {
  margin-right: 5px;
}

.header-container {
  margin-top: 16px;
}

.btn {
  border-radius: 0 !important;
  margin-bottom: 32px;
}

.jumbotron-container {
  color: white;
}

.section-panel {
  padding-top: 16px;
  margin-bottom: 30px;
}

.hidden-xs-down {
  @media (max-width: 1199px) {
    display: none !important;
  }
}

.hidden-xs-up {
  @media (min-width: 1200px) {
    display: none !important;
  }
}

$jumbotron_bg: #023467;
$primary: #df0053;
@import "node_modules/bootstrap/scss/bootstrap";

.jumbotron {
  margin-top: 16px;
}
